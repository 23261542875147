import { navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Layout from '../components/layout';
import * as actions from '../state/actions';
import { API_URL } from '../lib/utils';

// TODO: Conditionally fetch state from backend to verify that the source and
//     target ords are logged in and the migration may be started.
//     Conditionally, to avoid superfluous request if the state has been
//     fetched recently.

const LogInPage = ({ setLoginState, location }) => {
    const { addToast } = useToasts();
    handleLoginCallback(setLoginState, addToast);
    return (
        <Layout className="container d-flex flex-column">
            <h1 className="mx-auto">Welcome to EnsoData!</h1>
            <div className="
                flex-grow-1 d-flex align-items-center justify-content-center
            ">
                <a
                    className="btn btn-large btn-primary"
                    href={
                        `${API_URL}/oauth2/google?redirectUri=` +
                        // It is especially important to have a trailing slach
                        // on on the the pathname of `redirectUri`, because
                        // without it, GitLab Pages issues a redirect which
                        // discards the query string. Trailing slaches for all
                        // routes are enforced in `gatsby-browser.js`.
                        window.encodeURIComponent(location.href)
                    }
                >
                    Log in with Google
                </a>
            </div>
        </Layout>
    );
}

function handleLoginCallback(setLoginState, addToast) {
    try {
        // The backend will redirect back to this page with a JSON-encoded
        // query string, for example `http://localhost:8000/?{"success":true}`.
        // Attempt to parse it here.
        const login = JSON.parse(window.decodeURIComponent(
            // Strip off leading "?" when parsing query string content.
            window.location.search.slice(1),
        ));
        if (login) {
            if (login.success) {
                navigate('/', { replace: true });
                setLoginState(true);
            } else {
                addToast(login.message || 'Login failed!', {
                    appearance: 'error',
                });
                // Clear query string to not trigger this message again.
                clearCurrentQueryString();
            }
        }
    } catch (error) {
        // Errors here are safe to ignore.
    }
}

function clearCurrentQueryString() {
    const url = document.createElement('a');
    // Use anchor element to parse current URL to keep it the same, save for
    // the query string.
    url.href = window.location.href;
    url.search = '';
    window.history.replaceState(null, null, url.href);
}

export default connect(
    null,
    { setLoginState: actions.setLoginState },
)(LogInPage);
